import Location from './location';
import Category from './category';

export const enum OffersSortOptions {
  Popularity = 'POPULARITY',
  Rating = 'RATING',
  Distance = 'DISTANCE',
  Recent = 'RECENT',
  Percentage = 'PERCENTAGE',
  Activated = 'ACTIVATED',
  NotActivated = 'NOT_ACTIVATED',
  AlphaAZ = 'ALPHABETICAL_AZ',
  AlphaZA = 'ALPHABETICAL_ZA',
}

type Offer = {
  id: string;
  activatedOfferId?: string;
  locations: Location[];
  categories: Category[];
  maxEarningAmount: number | null;
  minPurchaseAmount: number | null;
  merchantToken: string;
  percentage: number;
  createdAt: string;
  updatedAt: string;
  startsAt: string;
  expiresAt?: string;
  merchantCreatedAt: string;
};

export default Offer;
