import { useQuery } from '@tanstack/react-query';

import { getBanners } from 'clients/cashbacker-client';

import { Banner } from 'models/banner';

import { GET_BANNERS_QUERY_KEY } from 'lib/constants';

const EMPTY_BANNERS: Banner[] = [];
const FIVE_MINUTES = 1000 * 60 * 5;
const TRANSACTION_BONUS_ID_PATTERN = /^TransactionBonus/;

export function useBanners() {
  const { isLoading, data: banners = EMPTY_BANNERS } = useQuery<Banner[]>(
    [GET_BANNERS_QUERY_KEY],
    async () => {
      let banners: Array<Banner>;
      try {
        const data = await getBanners();
        banners = data.banners;
      } catch {
        banners = [];
      }

      return banners;
    },
    {
      retry: false,
      staleTime: FIVE_MINUTES,
    },
  );

  return {
    isLoading,
    banners,
  };
}

// Temporary extra data hook to determine when a transaction bonus is available.
// The data values are hardcoded. Look to service this data from the backend
// in the future.
export function useAccountHasTransactionBonus() {
  const { banners } = useBanners();

  return banners.some(banner => banner.id.match(TRANSACTION_BONUS_ID_PATTERN));
}
