type geolocationParam = {
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export function calculateGeolocationDistance({
  lat1,
  lng1,
  lat2,
  lng2,
}: geolocationParam) {
  const radLat1 = (Math.PI * lat1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radTheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  // number of minutes in a degree
  const minInDegree = 60;
  // number of statute miles in a nautical mile
  const milesInNautical = 1.1515;
  dist = dist * minInDegree * milesInNautical;
  // returns distance in miles
  return dist;
}
