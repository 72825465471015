import { MarketButton, MarketHeader, MarketDialog } from '@market/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ES2Tracker from 'lib/eventstream/tracker';
import styles from './region-dialog.module.css';
import { pageClickEvent, pageViewEvent } from 'lib/eventstream/event-catalog';
import RegionCard from './region-card';
import { RegionIcon } from 'svgs';
import { REGION_LIST, REGION_NOT_AVAILABLE, ValidRegion, merchantCountPerRegion } from 'lib/constants';
import { MarketContextContext } from 'components/market-context-provider';

const RegionDialog = ({
  onMarketDialogDismissed,
}: {
  onMarketDialogDismissed: (event: any) => void;
}) => {
  const modal = useRef<HTMLMarketDialogElement | null>(null);
  const marketContextManager = useContext(MarketContextContext)!;
  const marketContextManagerActive = useState(
    Boolean(marketContextManager?.current?.active),
  );
  const [selectedRegion, setSelectedRegion] = useState<ValidRegion>();
  const [isPersistent, setIsPersistent] = useState<boolean>(true);

  const regionNextOnClick = async () => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Region Selection: ${selectedRegion}`,
      ),
    );
    setIsPersistent(false);
    // Update the selected region after fully closing the modal to avoid removing modal in index.page before close() get excuted
    marketContextManager?.current &&
      (await marketContextManager.current.close());
    onMarketDialogDismissed(selectedRegion);
  };

  useEffect(() => {
    if (
      marketContextManager?.current &&
      !marketContextManager.current.active &&
      modal.current
    ) {
      ES2Tracker.trackPageView(pageViewEvent('/region', REGION_NOT_AVAILABLE));
      marketContextManager.current.open?.(modal.current);
    }
  }, [marketContextManager, marketContextManagerActive]);

  return (
    <template>
      <MarketDialog
        className={styles.dialog}
        data-testid="dialog"
        ref={modal}
        {...(isPersistent && { persistent: true })}
      >
        <MarketHeader data-testid="dialog__header">
          <h2>Choose the city closest to you</h2>
        </MarketHeader>
        <div className={styles.localeList} data-testid="region__list">
          {REGION_LIST.map(region => (
            <RegionCard
              key={region}
              region={region}
              merchantCount={merchantCountPerRegion[region]}
              selected={region === selectedRegion}
              onClick={() => {
                setSelectedRegion(region);
              }}
            />
          ))}

          <div className={styles.comingSoon}>
            <div className={styles.localeIcon}>
              <RegionIcon selected={false} />
            </div>
            <div className={styles.localeInfo}>
              <h3>More coming soon!</h3>
            </div>
          </div>
          <MarketButton
            data-testid="next__button"
            rank="primary"
            size="medium"
            slot="actions"
            {...(!selectedRegion && { disabled: true })}
            onClick={regionNextOnClick}
          >
            next
          </MarketButton>
        </div>
      </MarketDialog>
    </template>
  );
};

export default RegionDialog;
