import { ReactNode } from 'react';
import styles from './peel-off-button.module.css';

/**
 * Wrapper component intended to be used with MarketButton to
 * apply a peel off button look.
 * <PeelOffButton>
 *   <MarketButton/>
 *     Text
 *    </MarketButton>
 * </PeelOffButton>
 */

type PeelOffButtonProps = {
  children: ReactNode;
};

export const PeelOffButton = (props: PeelOffButtonProps) => {
  return (
    <div className={styles.buttonWrapper}>
      {props.children}
      <div className={styles.peel} />
      <div className={styles.border} />
    </div>
  );
};
