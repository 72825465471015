export const diffDays = (dateString: string) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(
    Math.ceil((new Date(dateString).valueOf() - Date.now()) / oneDay),
  );
};

export const getLocalizedDateTime = ({
  dateString,
  timeZone,
  options,
}: {
  dateString: string,
  options?: Intl.DateTimeFormatOptions,
  timeZone?: string,
}) => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  let language = 'en-US';

  if (typeof window !== 'undefined') {
    language = window.navigator?.language;
  }

  return new Date(dateString).toLocaleString(language, {
    ...options || defaultOptions,
    ...(timeZone && { timeZone }),
  });
};

export const dateToDisplayString = (date: Date) => {
  return `${date.toLocaleString('default', {
    month: 'long',
  })} ${date.getFullYear()}`;
};

export const getPastYearMonths = () => {
  const currentDate = new Date();
  // Set to to first day of month to avoid leap year edge case
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const pastYearMonths = [];

  for (let i = 0; i < 11; i++) {
    const date = new Date(firstDay.setMonth(firstDay.getMonth() - 1));
    pastYearMonths.push(dateToDisplayString(date));
  }

  return [dateToDisplayString(new Date()), ...pastYearMonths];
};

export const monthNameToNumber = (monthName: string) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return months.indexOf(monthName);
}
