import React from 'react';
import styles from './rating.module.css';
import ES2Tracker from 'lib/eventstream/tracker';
import { pageClickEvent } from '../../lib/eventstream/event-catalog';
import Location from 'models/location';
import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

type RatingProps = {
  isNumPositionFront: boolean;
  showReviews: boolean;
  merchantToken: string;
  location: Location;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE
  color: string;
};

const Rating = ({
  isNumPositionFront,
  showReviews,
  merchantToken,
  location,
  region,
  color = '#000',
}: RatingProps) => {
  const percent = (Number(location.rating) / 5) * 100;

  const trackReviewsClick = () => {
    const es2Params = {
      detail: merchantToken,
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'link-click',
        `Marketing: Buyer: Marketplace: Reviews: ${region}`,
        es2Params,
      ),
    );
  };

  return (
    <div className={styles.rating}>
      {isNumPositionFront && (
        <div className={styles.front}>
          <p className="paragraph-10 semibold-10">{location.rating}</p>
        </div>
      )}
      <div className={styles.stars}>
        <div
          data-testid="filled-stars"
          className={styles.filled}
          style={{ width: `${percent}%`, color }}
        >
          ★★★★★
        </div>
        <div className={styles.empty}>★★★★★</div>
      </div>
      {!isNumPositionFront && (
        <div className={styles.back}>
          <p className="paragraph-10 semibold-10">{location.rating}</p>
        </div>
      )}
      {showReviews && (
        <div
          className="google-review__reviews paragraph-20"
          onClick={trackReviewsClick}
        >
          <a
            href={`${location.googleMapsUrl}`}
            target="_blank"
            rel="noreferrer"
          >{`${location.reviewCount} reviews`}</a>
        </div>
      )}
    </div>
  );
};

export default Rating;
