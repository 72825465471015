import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBottleDroplet,
  faMugHot,
  faIceCream,
  faEgg,
  faBreadSlice,
  faBurger,
  faPizzaSlice,
  faLeaf,
  faBowlRice,
  faPepperHot,
} from '@fortawesome/free-solid-svg-icons';

// Special value to represent any category
export const ALL_CATEGORY = 'All';

export const categoryIcons: { [key: string]: IconDefinition } = {
  Coffee: faMugHot,
  Breakfast: faEgg,
  Drinks: faBottleDroplet,
  Asian: faBowlRice,
  Sandwiches: faBreadSlice,
  Salad: faLeaf,
  Pizza: faPizzaSlice,
  Dessert: faIceCream,
  American: faBurger,
  Mexican: faPepperHot,
};

type Category = {
  id: string;
  name: string;
};

export default Category;
