import Offer, { OffersSortOptions } from 'models/offer';
import { calculateGeolocationDistance, LatLng } from 'lib/geolocation';

const getClosestMerchantLocationPerOffer = (
  offer: Offer,
  centerLatLng: LatLng,
) => {
  return Math.min(
    ...offer.locations.map(location =>
      calculateGeolocationDistance({
        lat1: centerLatLng.lat,
        lng1: centerLatLng.lng,
        lat2: Number(location.lat),
        lng2: Number(location.lng),
      }),
    ),
  );
};

export const sortOffersByDistance = (
  offer1: Offer,
  offer2: Offer,
  centerLatLng: LatLng,
) => {
  const merchantLocationDistance1 = getClosestMerchantLocationPerOffer(
    offer1,
    centerLatLng,
  );
  const merchantLocationDistance2 = getClosestMerchantLocationPerOffer(
    offer2,
    centerLatLng,
  );
  return merchantLocationDistance1 - merchantLocationDistance2;
};

const sortOffers = (sortValue: OffersSortOptions, a: Offer, b: Offer) => {
  switch (sortValue) {
    case OffersSortOptions.Popularity:
      return a.locations[0].popularity > b.locations[0].popularity ? 1 : -1;

    case OffersSortOptions.Rating:
      return a.locations?.[0].rating > b.locations?.[0].rating ? -1 : 1;

    case OffersSortOptions.Recent:
      return a.merchantCreatedAt > b.merchantCreatedAt ? -1 : 1;

    case OffersSortOptions.Percentage:
      return a.percentage > b.percentage ? -1 : 1;

    case OffersSortOptions.Activated:
      return a.activatedOfferId ? -1 : 1;

    case OffersSortOptions.NotActivated:
      return a.activatedOfferId ? 1 : -1;

    case OffersSortOptions.AlphaAZ:
      return a.locations[0].name > b.locations[0].name ? 1 : -1;

    case OffersSortOptions.AlphaZA:
      return a.locations[0].name > b.locations[0].name ? -1 : 1;

    default:
      return a.locations[0].name > b.locations[0].name ? 1 : -1;
  }
};

export const sortOffersFn =
  (sortValue: OffersSortOptions, centerLatLng?: LatLng | null) =>
  (a: Offer, b: Offer) => {
    if (sortValue === OffersSortOptions.Distance) {
      if (centerLatLng) {
        return sortOffersByDistance(a, b, centerLatLng);
      } else {
        return sortOffers(sortValue, a, b);
      }
    } else {
      return sortOffers(sortValue, a, b);
    }
  };
