import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  activateOffer as _activateOffer,
  searchOffers,
} from 'clients/cashbacker-client';
import {
  GET_OFFERS_QUERY_KEY,
  INVALID_OFFER_ERROR_MESSAGE,
  ValidRegion,
  REGION_NOT_AVAILABLE_TYPE,
} from 'lib/constants';
import { toast } from 'lib/toast';
import Offer, { OffersSortOptions } from 'models/offer';
import { OffersActivatedFilterOptions } from 'hooks/types';
import { sortOffersFn } from 'hooks/use-offers/sort-offers';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { filterOffersFn } from './filter-offers';
import { LatLng } from 'lib/geolocation';
import { SearchQuery } from 'clients/types';

/**
 * TODO: Add other list states once we move everything to the backend
 * - filters
 * - pagination
 * - ordering
 */
export type OffersListState = {
  textSearchValue: string;
};

export function useOffers(options?: {
  getOffersEnabled?: boolean;
  initialOffers?: Array<Offer>;
  sort?: OffersSortOptions;
  centerLatLng?: LatLng;
  offersActivatedFilter?: OffersActivatedFilterOptions;
  offersCategoryFilter?: string;
  textSearchValue?: string;
  region?: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
}) {
  const router = useRouter();
  const queryClient = useQueryClient();

  const {
    getOffersEnabled,
    initialOffers,
    sort,
    centerLatLng,
    offersActivatedFilter,
    offersCategoryFilter,
    textSearchValue,
    region,
  } = {
    getOffersEnabled: true,
    initialOffers: [],
    sort: OffersSortOptions.AlphaAZ,
    centerLatLng: null,
    offersActivatedFilter: OffersActivatedFilterOptions.All,
    offersCategoryFilter: undefined,
    textSearchValue: null,
    region: ValidRegion.portland,
    ...options,
  };

  const {
    isLoading,
    isFetching,
    data: offers,
    isError: isOfferError
  } = useQuery<Offer[]>(
    [GET_OFFERS_QUERY_KEY, textSearchValue, region],
    async () => {
      const searchQuery: SearchQuery = {
        filter: {
          ...(textSearchValue && {
            search: textSearchValue,
          }),
        },
      };
      const { offers } = await searchOffers(searchQuery, region);
      return offers;
    },
    {
      enabled: getOffersEnabled,
      initialData: initialOffers,
      retry: false,
      onError: () => {
        toast.error();
        return initialOffers;
      },
    },
  );

  const activateOffer = useCallback(
    async (offerId: string): Promise<Offer | null> => {
      try {
        const { offer: activatedOffer } = await _activateOffer(offerId);
        queryClient.setQueriesData(
          [GET_OFFERS_QUERY_KEY],
          (oldOffersList: Array<Offer> | undefined) =>
            oldOffersList?.map((offer: Offer) =>
              offer.id === activatedOffer.id ? activatedOffer : offer,
            ),
        );
        return activatedOffer;
      } catch (err: any) {
        if (err.status === 401) {
          router.push('/');
        }
        if (err.status === 400) {
          toast.error(INVALID_OFFER_ERROR_MESSAGE);
        } else {
          toast.error();
        }
        return null;
      }
    },
    [],
  );

  return {
    isLoadingOffers: isLoading || isFetching,
    offers: offers
      ? offers
          .filter(
            filterOffersFn({ offersActivatedFilter, offersCategoryFilter }),
          )
          .sort(sortOffersFn(sort, centerLatLng))
      : [],
    activatedOffers: offers?.filter(offer => Boolean(offer.activatedOfferId)),
    activateOffer,
    isOfferError,
  };
}
