import { OffersActivatedFilterOptions } from 'hooks/types';
import Category, { ALL_CATEGORY } from 'models/category';
import Offer from 'models/offer';

const filterOffersActivated =
  (offersActivatedFilter: OffersActivatedFilterOptions) => (offer: Offer) => {
    switch (offersActivatedFilter) {
      case OffersActivatedFilterOptions.All:
        return true;
      case OffersActivatedFilterOptions.Activated:
        return Boolean(offer.activatedOfferId);
      case OffersActivatedFilterOptions.NotActivated:
        return !offer.activatedOfferId;
      default:
        return true;
    }
  };

const filterOffersCategory =
  (selectedCategory: string = ALL_CATEGORY) =>
  (offer: Offer) => {
    if (selectedCategory === ALL_CATEGORY) return true;

    return offer.categories.some((offerCategory: Category) => {
      return offerCategory.name === selectedCategory;
    });
  };

export const filterOffersFn =
  ({
    offersActivatedFilter,
    offersCategoryFilter,
  }: {
    offersActivatedFilter: OffersActivatedFilterOptions;
    offersCategoryFilter?: string;
  }) =>
  (offer: Offer) => {
    const filters = [
      filterOffersActivated(offersActivatedFilter),
      filterOffersCategory(offersCategoryFilter),
    ];

    return filters.every(filter => filter(offer));
  };
