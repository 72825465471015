import { MarketActivityIndicator } from '@market/react';
import styles from './offers-loading-screen.module.css';

export const OffersLoadingScreen = () => {
  return (
    <main className={styles.main} data-testid="offers-loading-screen__main">
      <div className={styles.inner}>
        <MarketActivityIndicator size="large" />
        <p className="heading-30 black-text">
          Hold tight we&apos;re loading special offers just for you
        </p>
      </div>
    </main>
  );
};
