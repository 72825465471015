import { MarketButton } from '@market/react';
import { pageClickEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import React from 'react';
import styles from 'components/v2/places-toggle.module.css';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

export enum Mode {
  List,
  Map,
}

export type PlacesToggleProps = {
  mode: Mode;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE
  compact?: boolean;
};

const PlacesToggle = ({ mode, region, compact = false }: PlacesToggleProps) => {
  const trackClick = (mode: Mode) => {
    if (mode === Mode.List) {
      ES2Tracker.trackPageClick(
        pageClickEvent(
          'button-click',
          `Marketing: Buyer: Marketplace: Toggle List: ${region}`,
        ),
      );
    } else if (mode === Mode.Map) {
      ES2Tracker.trackPageClick(
        pageClickEvent(
          'button-click',
          `Marketing: Buyer: Marketplace: Toggle Map: ${region}`,
        ),
      );
    }
  };

  return (
    <div className={styles.placeToggleContainer}>
      <div
        className={`${styles.viewMode} ${
          mode === Mode.Map ? styles.onMapPage : styles.onListPage
        }`}
      >
        <Link href="/" onClick={() => trackClick(Mode.List)}>
          <MarketButton
            data-testid="places-toggle-list-button"
            className={styles.listToggleButton}
            rank={mode === Mode.List ? 'primary' : 'tertiary'}
            size="small"
          >
            <span slot="icon">
              <FontAwesomeIcon icon={faList} width={13} height={12} />
            </span>
            {!compact && <span className={styles.listText}>List</span>}
          </MarketButton>
        </Link>
        <Link href="/map" onClick={() => trackClick(Mode.Map)}>
          <MarketButton
            data-testid="places-toggle-map-button"
            className={styles.mapToggleButton}
            rank={mode === Mode.Map ? 'primary' : 'tertiary'}
            size="small"
          >
            <span slot="icon">
              <FontAwesomeIcon icon={faLocationDot} width={17} height={16} />
            </span>
            {!compact && <span className={styles.mapText}>Map</span>}
          </MarketButton>
        </Link>
      </div>
    </div>
  );
};

export default PlacesToggle;
