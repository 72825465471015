import Location from 'models/location';

import styles from './merchant-logo.module.css';

export type MerchantLogoProps = {
  location: Location;
  size?: number;
};

// set offsets via css (esp for media queries)
const MerchantLogo = ({ location, size = 60 }: MerchantLogoProps) => {
  return (
    <div
      className={styles.logo}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <img
        src={location.logoUrl}
        alt={`Logo of ${location.name}`}
        width={size}
        height={size}
      />
    </div>
  );
};

export default MerchantLogo;
