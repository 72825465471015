import { MarketActionCard } from '@market/react';
import styles from './region-card.module.css';
import RegionIcon from 'svgs/region-icon';
import { REGION_NAME_MAP, ValidRegion } from 'lib/constants';

const RegionCard = ({
  region,
  selected,
  transient,
  onClick,
  merchantCount,
}: {
  region?: ValidRegion;
  selected: boolean;
  merchantCount?: number;
  transient?: boolean;
  onClick?: () => void;
}) => {
  return (
    <MarketActionCard
      className={styles.locale}
      onClick={onClick}
      {...(selected && { selected: true })}
      {...(transient && { transient: true })}
    >
      <div
        data-testid="region-card"
        className={`${styles.localeIcon} ${
          selected ? styles.selected : styles.unselected
        }`}
      >
        <RegionIcon region={region} selected={selected} />
      </div>
      <div className={styles.localeInfo}>
        {region ? (
          <>
            <h3>{REGION_NAME_MAP[region]}</h3>
            {
              <p>{merchantCount}+ local businesses</p>
            }
          </>
        ) : (
          <h3>More coming soon!</h3>
        )}
      </div>
    </MarketActionCard>
  );
};
export default RegionCard;
