import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { GET_ACCOUNT_QUERY_KEY } from 'lib/constants';

import { useAccount } from './use-account';

const THREE_SECONDS = 3000;
const MAX_RETRIES = 100;

/**
 * Custom hook that refetches GET /accounts/me until the response returns with isLoadingMerchantRelationships === false
 * IMPORTANT: Only use this hook in the top-level page component
 * If used in multiple components that renders on a single page, this will cause the useEffect, and hence the GET requests, to fire multiple times at once.
 *
 * @returns {
 *   isLoadingMerchantRelationships: boolean
 * }
 */
export function useWaitForMerchantRelationships(
  options: {
    maxRetries?: number;
    waitMs?: number;
  } = {},
): {
  isLoadingMerchantRelationships: boolean;
} {
  const { maxRetries, waitMs } = options;
  const queryClient = useQueryClient();
  const { account } = useAccount({
    hasSessionCookie: true,
  });
  const router = useRouter();

  const [retries, setRetries] = useState(0);

  const isLoadingMerchantRelationships =
    account?.isLoadingMerchantRelationships === true;

  useEffect(() => {
    if (
      account?.isLoadingMerchantRelationships === true &&
      // If it's taking longer than 5 minutes, there's likely something wrong which ONCALL would be looking into
      // So stop sending requests to cashbacker
      retries < (maxRetries || MAX_RETRIES)
    ) {
      setTimeout(() => {
        queryClient.invalidateQueries([GET_ACCOUNT_QUERY_KEY]);
        setRetries(count => count + 1);
      }, waitMs || THREE_SECONDS);
    }

    // If we needed to retry because merchantRelationships were loading, and we finally completed loading
    if (account?.isLoadingMerchantRelationships === false && retries > 0) {
      // refresh the page to show the appropriate content
      // we need to hard refresh because some data might be server rendered only
      router.reload();
    }
  }, [account, queryClient, retries, router, maxRetries, waitMs]);

  return {
    isLoadingMerchantRelationships,
  };
}
